var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-auto-suggest" }, [
    _c(
      "div",
      { staticClass: "flex items-center relative" },
      [
        _c("vs-input", {
          ref: "input",
          staticClass: "z-50",
          class: _vm.inputClassses,
          attrs: {
            placeholder: _vm.placeholder,
            "icon-pack": "feather",
            icon: "icon-search",
            "icon-no-border": ""
          },
          on: {
            keyup: [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape"
                  ])
                ) {
                  return null
                }
                return _vm.escPressed($event)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp"
                  ])
                ) {
                  return null
                }
                return _vm.increaseIndex(false)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown"
                  ])
                ) {
                  return null
                }
                return _vm.increaseIndex($event)
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.suggestionSelected($event)
              }
            ],
            focus: _vm.updateInputFocus,
            blur: function($event) {
              return _vm.updateInputFocus(false)
            }
          },
          model: {
            value: _vm.searchQuery,
            callback: function($$v) {
              _vm.searchQuery = $$v
            },
            expression: "searchQuery"
          }
        })
      ],
      1
    ),
    _c(
      "ul",
      {
        ref: "scrollContainer",
        staticClass:
          "auto-suggest-suggestions-list z-50 rounded-lg mt-2 shadow-lg overflow-x-hidden",
        class: { hidden: !_vm.inputFocused },
        attrs: { tabindex: "-1" },
        on: {
          mouseenter: function($event) {
            _vm.insideSuggestions = true
          },
          mouseleave: function($event) {
            _vm.insideSuggestions = false
          },
          focus: _vm.updateInputFocus,
          blur: function($event) {
            return _vm.updateInputFocus(false)
          }
        }
      },
      [
        _vm._l(_vm.filteredData, function(item, grp_index) {
          return _c(
            "li",
            {
              key: grp_index,
              ref: "grp_list",
              refInFor: true,
              staticClass: "auto-suggest__suggestion-group-container"
            },
            [
              !_vm.hideGroupTitle
                ? _c(
                    "p",
                    {
                      staticClass:
                        "auto-suggest__suggestion-group-title pt-3 pb-1 px-4"
                    },
                    [_vm._t("group", null, { group_name: item.name })],
                    2
                  )
                : _vm._e(),
              _c(
                "ul",
                _vm._l(item.data, function(suggestion, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass:
                        "auto-suggest__suggestion-group__suggestion py-3 px-4 cursor-pointer",
                      class: {
                        "vx-auto-suggest__current-selected":
                          _vm.currentSelected == grp_index + "." + index
                      },
                      on: {
                        mouseenter: function($event) {
                          _vm.currentSelected = grp_index + "." + index
                        },
                        click: function($event) {
                          return _vm.suggestionSelected(suggestion)
                        }
                      }
                    },
                    [_vm._t("item", null, { suggestion: suggestion })],
                    2
                  )
                }),
                0
              )
            ]
          )
        }),
        (_vm.data || []).length == 0 && _vm.searchQuery
          ? _c(
              "li",
              {
                ref: "grp_list",
                staticClass: "auto-suggest__suggestion-group-container"
              },
              [
                _c("ul", [
                  _c(
                    "li",
                    {
                      staticClass:
                        "auto-suggest__suggestion-group__suggestion py-3 px-4 no-results"
                    },
                    [
                      _vm._t("noResult", [
                        _c("p", [_vm._v("Nenhum resultado encontrado.")])
                      ])
                    ],
                    2
                  )
                ])
              ]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }