var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar-bookmarks flex items-center" }, [
    _c(
      "ul",
      { staticClass: "vx-navbar__starred-pages" },
      [
        _c(
          "draggable",
          {
            staticClass: "flex cursor-move",
            attrs: { group: { name: "pinList" } },
            model: {
              value: _vm.starredPagesLimited,
              callback: function($$v) {
                _vm.starredPagesLimited = $$v
              },
              expression: "starredPagesLimited"
            }
          },
          _vm._l(_vm.starredPagesLimited, function(page) {
            return _c(
              "li",
              { key: page.url, staticClass: "starred-page" },
              [
                _c(
                  "vx-tooltip",
                  {
                    attrs: {
                      text: page.title,
                      position: "bottom",
                      delay: ".3s"
                    }
                  },
                  [
                    _c("feather-icon", {
                      staticClass: "p-2 cursor-pointer",
                      attrs: {
                        svgClasses: ["h-6 w-6 stroke-current", _vm.textColor],
                        icon: page.icon
                      },
                      on: {
                        click: function($event) {
                          _vm.$router.push(page.url).catch(function() {})
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          0
        )
      ],
      1
    ),
    _vm.starredPagesMore.length
      ? _c(
          "div",
          { staticClass: "vx-navbar__starred-pages--more-dropdown" },
          [
            _c(
              "vs-dropdown",
              {
                staticClass: "cursor-pointer",
                attrs: { "vs-custom-content": "", "vs-trigger-click": "" }
              },
              [
                _c("feather-icon", {
                  staticClass: "cursor-pointer p-2",
                  attrs: { icon: "ChevronDownIcon", svgClasses: "h-4 w-4" }
                }),
                _c("vs-dropdown-menu", [
                  _c(
                    "ul",
                    { staticClass: "vx-navbar__starred-pages-more--list" },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "cursor-move",
                          attrs: { group: { name: "pinList" } },
                          model: {
                            value: _vm.starredPagesMore,
                            callback: function($$v) {
                              _vm.starredPagesMore = $$v
                            },
                            expression: "starredPagesMore"
                          }
                        },
                        _vm._l(_vm.starredPagesMore, function(page) {
                          return _c(
                            "li",
                            {
                              key: page.url,
                              staticClass:
                                "starred-page--more flex items-center cursor-pointer",
                              on: {
                                click: function($event) {
                                  _vm.$router
                                    .push(page.url)
                                    .catch(function() {})
                                }
                              }
                            },
                            [
                              _c("feather-icon", {
                                staticClass: "ml-2 mr-1",
                                attrs: {
                                  icon: page.icon,
                                  svgClasses: [
                                    "h-5 w-5 stroke-current",
                                    _vm.textColor
                                  ]
                                }
                              }),
                              _c("span", { staticClass: "px-2 pt-2 pb-1" }, [
                                _vm._v(_vm._s(page.title))
                              ])
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "bookmark-container" },
      [
        _c(
          "notification-drop-down",
          {
            attrs: {
              unreadNotifications: _vm.favorites,
              title: "Meus Favoritos",
              message: "Você não possui nenhuma proposta favorita."
            }
          },
          [
            _c("feather-icon", {
              staticClass: "cursor-pointer p-2 notfication",
              attrs: {
                icon: "StarIcon",
                svgClasses: ["stoke-current", _vm.textColor]
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  _vm.showBookmarkPagesDropdown = !_vm.showBookmarkPagesDropdown
                }
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }