<template>
  <div class="the-navbar__user-meta flex items-center" v-if="isAuthenticated">
    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <button>
        <!-- <img v-if="userInfo.photoURL" key="onlineImg" :src="userInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" /> -->
        <i class="onpoint-user user-button"></i>
      </button>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul class="m-0 p-0">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            v-if="
              profileHelpers.checkPermission([
                profileHelpers.menuFuncionalitiesEnum.GERENCIAR_CORRETORA,
                profileHelpers.menuFuncionalitiesEnum.VISUALIZAR_CORRETORA
              ])
            "
            @click="goToPerfilCorretora()"
          >
            <i class="onpoint-user" style="position:relative;top:2px;"></i>
            <span class="ml-2">Perfil</span>
          </li>
          <template
            v-if="
              (advisorInfo || {}).PersonUniqueId && !profileHelpers.isAdvisor()
            "
          >
            <vs-divider class="m-1" />
            <li
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="returnAdivisor()"
            >
              <i class="onpoint-user" style="position:relative;top:2px;"></i>
              <span class="ml-2">Voltar para Perfil Assessoria</span>
            </li>
          </template>
          <vs-divider class="m-1" />

          <div
            v-if="!profileHelpers.isInsurance() && !profileHelpers.isAdvisor()"
          >
            <li
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="goToMeusTermosAceite"
            >
              <i class="onpoint-file-text" style="position:relative;top:2px;"></i>
              <span class="ml-2">Meus termos de aceite</span>
            </li>

            <vs-divider class="m-1" />
          </div>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="onLogout"
          >
            <i class="onpoint-sign-out" style="position:relative;top:2px;"></i>
            <span class="ml-2">Sair do sistema</span>
          </li>

          <vs-divider class="m-1" />
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ userInfo.DisplayName || "Desconhecido" }}</p>
      <small>{{ userInfo.ProfileName || "Desconhecido" }}</small>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import * as authUtils from "../../../../utils/auth";
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "profile-drop-down",
  data() {
    return {
      profileHelpers: PerfilHelpers
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "userInfo", "advisorInfo"])
  },
  methods: {
    // ...mapActions('auth', ['logout']),
    ...mapActions("auth", ["returnAdvisor", "login"]),
    goToPerfilCorretora() {
      this.$router.push("/corretor");
    },
    goToMeusTermosAceite() {
      this.$router.push("/termos-aceite");
    },
    onLogout() {
      this.$appInsights.trackEvent({ name: "logout", properties: { place: 'menu-superior', action: "button-click", slug: "logout-menu-superior" }});
      authUtils.logout();
    },
    returnAdivisor() {
      this.$vs.loading();
      return this.returnAdvisor({
        usuario: this.advisorInfo.Username,
        advisor: true
      })
        .then(() => {
          this.$router.push("/redirect-to-home");
        })
        .catch(errors => {
          this.$onpoint.errorModal(
            ((errors.response || {}).data || {}).error_description ||
              "Não foi possível realizar o Login."
          );
        })
        .finally(() => this.$vs.loading.close());
    }
  }
};
</script>
<style lang="scss" scoped>
a:hover {
  color: white !important;
}

button .user-button{
  border: none !important;
  background-color: #626262;
  color: white;
  font-size: 25px;
  border-radius: 50px;
  padding: 4px; 
}

button, .vdp-datepicker input{
  position: relative;
  top:1px;
  border: none !important;
   margin-right: 5px;
}
</style>
