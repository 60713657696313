var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      { staticClass: "vx-navbar-wrapper", class: _vm.classObj },
      [
        _c(
          "vs-navbar",
          {
            staticClass: "vx-navbar navbar-custom navbar-skelton",
            class: _vm.textColor,
            attrs: { color: _vm.navbarColorLocal }
          },
          [
            _c("feather-icon", {
              staticClass: "sm:inline-flex lp:hidden cursor-pointer p-2",
              attrs: { icon: "MenuIcon" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.showSidebar($event)
                }
              }
            }),
            _c("search-bar", { staticClass: "mr-4" }),
            _c("vs-spacer"),
            _c("profile-drop-down", {
              staticClass: "mr-2 mt-1 md:mt-0 md:mr-8"
            }),
            _c(
              "notification-drop-down",
              {
                attrs: {
                  unreadNotifications: _vm.recentProposals,
                  OldNotifications: _vm.oldNotification,
                  title: "Notificações",
                  message: "Você não possui nenhuma notificação."
                }
              },
              [
                _c("feather-icon", {
                  staticClass: "cursor-pointer mt-1 mr-4 notfication",
                  attrs: { icon: "BellIcon", badge: _vm.recentProposals.length }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }