var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "footer",
    { staticClass: "the-footer flex-wrap justify-between", class: _vm.classes },
    [
      _c("p", [
        _c("span", [_vm._v("COPYRIGHT ©")]),
        _c("span", [_vm._v(_vm._s(new Date().getFullYear()) + " ")]),
        _c(
          "a",
          {
            attrs: {
              href: "https://1.envato.market/vuexy_admin",
              target: "_blank",
              rel: "nofollow"
            }
          },
          [_vm._v("Pixinvent")]
        ),
        _c("span", { staticClass: "hidden sm:inline-block" }, [
          _vm._v(", All rights Reserved")
        ])
      ]),
      _c(
        "span",
        { staticClass: "md:flex hidden items-center" },
        [
          _c("span", [_vm._v("Hand-crafted & Made with")]),
          _c("feather-icon", {
            staticClass: "ml-2",
            attrs: {
              icon: "HeartIcon",
              svgClasses: "stroke-current text-danger w-6 h-6"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }