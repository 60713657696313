var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vs-sidebar--item",
      class: [
        { "vs-sidebar-item-active": _vm.activeLink },
        { "disabled-item pointer-events-none": _vm.isDisabled }
      ]
    },
    [
      _c(
        "a",
        {
          class: [{ "router-link-active": _vm.activeLink }],
          on: {
            click: function($event) {
              return _vm.goTo(_vm.to)
            }
          }
        },
        [_c("i", { class: _vm.icon }), _vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }